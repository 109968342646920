// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-applying-ai-technologies-to-rare-disease-diagnosis-js": () => import("./../../../src/pages/blog/applying-ai-technologies-to-rare-disease-diagnosis.js" /* webpackChunkName: "component---src-pages-blog-applying-ai-technologies-to-rare-disease-diagnosis-js" */),
  "component---src-pages-blog-crypto-casino-js": () => import("./../../../src/pages/blog/crypto-casino.js" /* webpackChunkName: "component---src-pages-blog-crypto-casino-js" */),
  "component---src-pages-blog-decentralised-finance-2022-js": () => import("./../../../src/pages/blog/decentralised-finance-2022.js" /* webpackChunkName: "component---src-pages-blog-decentralised-finance-2022-js" */),
  "component---src-pages-blog-drupal-10-js": () => import("./../../../src/pages/blog/drupal10.js" /* webpackChunkName: "component---src-pages-blog-drupal-10-js" */),
  "component---src-pages-blog-drupal-7-end-of-life-js": () => import("./../../../src/pages/blog/drupal-7-end-of-life.js" /* webpackChunkName: "component---src-pages-blog-drupal-7-end-of-life-js" */),
  "component---src-pages-blog-drupal-vs-magnolia-js": () => import("./../../../src/pages/blog/drupal-vs-magnolia.js" /* webpackChunkName: "component---src-pages-blog-drupal-vs-magnolia-js" */),
  "component---src-pages-blog-ecommerce-trends-2020-js": () => import("./../../../src/pages/blog/ecommerce-trends-2020.js" /* webpackChunkName: "component---src-pages-blog-ecommerce-trends-2020-js" */),
  "component---src-pages-blog-how-gdpr-changed-seo-content-marketing-js": () => import("./../../../src/pages/blog/how-gdpr-changed-seo-content-marketing.js" /* webpackChunkName: "component---src-pages-blog-how-gdpr-changed-seo-content-marketing-js" */),
  "component---src-pages-blog-how-to-improve-digital-marketing-roi-js": () => import("./../../../src/pages/blog/how-to-improve-digital-marketing-roi.js" /* webpackChunkName: "component---src-pages-blog-how-to-improve-digital-marketing-roi-js" */),
  "component---src-pages-blog-how-to-invest-in-bitcoin-js": () => import("./../../../src/pages/blog/how-to-invest-in-bitcoin.js" /* webpackChunkName: "component---src-pages-blog-how-to-invest-in-bitcoin-js" */),
  "component---src-pages-blog-how-to-lead-ai-iot-blockchain-projects-js": () => import("./../../../src/pages/blog/how-to-lead-ai-iot-blockchain-projects.js" /* webpackChunkName: "component---src-pages-blog-how-to-lead-ai-iot-blockchain-projects-js" */),
  "component---src-pages-blog-icon-bulgaria-nearshore-development-js": () => import("./../../../src/pages/blog/icon-bulgaria-nearshore-development.js" /* webpackChunkName: "component---src-pages-blog-icon-bulgaria-nearshore-development-js" */),
  "component---src-pages-blog-icon-open-ai-chat-js": () => import("./../../../src/pages/blog/icon-open-ai-chat.js" /* webpackChunkName: "component---src-pages-blog-icon-open-ai-chat-js" */),
  "component---src-pages-blog-inhouse-vs-nearshoresoftware-development-team-js": () => import("./../../../src/pages/blog/inhouse-vs-nearshoresoftware-development-team.js" /* webpackChunkName: "component---src-pages-blog-inhouse-vs-nearshoresoftware-development-team-js" */),
  "component---src-pages-blog-iron-deficiency-day-2018-js": () => import("./../../../src/pages/blog/iron-deficiency-day-2018.js" /* webpackChunkName: "component---src-pages-blog-iron-deficiency-day-2018-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blog-magento-vs-woocommerce-js": () => import("./../../../src/pages/blog/magento-vs-woocommerce.js" /* webpackChunkName: "component---src-pages-blog-magento-vs-woocommerce-js" */),
  "component---src-pages-blog-nearshoring-bulgaria-js": () => import("./../../../src/pages/blog/nearshoring-bulgaria.js" /* webpackChunkName: "component---src-pages-blog-nearshoring-bulgaria-js" */),
  "component---src-pages-blog-number-20-for-php-development-js": () => import("./../../../src/pages/blog/number-20-for-php-development.js" /* webpackChunkName: "component---src-pages-blog-number-20-for-php-development-js" */),
  "component---src-pages-blog-pharma-digital-marketing-case-studies-js": () => import("./../../../src/pages/blog/pharma-digital-marketing-case-studies.js" /* webpackChunkName: "component---src-pages-blog-pharma-digital-marketing-case-studies-js" */),
  "component---src-pages-blog-project-development-model-js": () => import("./../../../src/pages/blog/project-development-model.js" /* webpackChunkName: "component---src-pages-blog-project-development-model-js" */),
  "component---src-pages-blog-risks-of-not-updating-to-drupal-10-js": () => import("./../../../src/pages/blog/risks-of-not-updating-to-drupal-10.js" /* webpackChunkName: "component---src-pages-blog-risks-of-not-updating-to-drupal-10-js" */),
  "component---src-pages-blog-sams-app-development-case-study-js": () => import("./../../../src/pages/blog/sams-app-development-case-study.js" /* webpackChunkName: "component---src-pages-blog-sams-app-development-case-study-js" */),
  "component---src-pages-blog-security-checklist-for-developers-js": () => import("./../../../src/pages/blog/security-checklist-for-developers.js" /* webpackChunkName: "component---src-pages-blog-security-checklist-for-developers-js" */),
  "component---src-pages-blog-seo-best-practices-js": () => import("./../../../src/pages/blog/seo-best-practices.js" /* webpackChunkName: "component---src-pages-blog-seo-best-practices-js" */),
  "component---src-pages-blog-sofia-nearshore-development-team-js": () => import("./../../../src/pages/blog/sofia-nearshore-development-team.js" /* webpackChunkName: "component---src-pages-blog-sofia-nearshore-development-team-js" */),
  "component---src-pages-blog-software-development-life-cycle-steps-js": () => import("./../../../src/pages/blog/software-development-life-cycle-steps.js" /* webpackChunkName: "component---src-pages-blog-software-development-life-cycle-steps-js" */),
  "component---src-pages-blog-successful-personalised-nearshore-development-js": () => import("./../../../src/pages/blog/successful-personalised-nearshore-development.js" /* webpackChunkName: "component---src-pages-blog-successful-personalised-nearshore-development-js" */),
  "component---src-pages-blog-the-future-of-your-customer-relationship-js": () => import("./../../../src/pages/blog/the-future-of-your-customer-relationship.js" /* webpackChunkName: "component---src-pages-blog-the-future-of-your-customer-relationship-js" */),
  "component---src-pages-blog-top-20-for-healthcare-software-js": () => import("./../../../src/pages/blog/top-20-for-healthcare-software.js" /* webpackChunkName: "component---src-pages-blog-top-20-for-healthcare-software-js" */),
  "component---src-pages-blog-top-ecommerce-best-practices-js": () => import("./../../../src/pages/blog/top-ecommerce-best-practices.js" /* webpackChunkName: "component---src-pages-blog-top-ecommerce-best-practices-js" */),
  "component---src-pages-blog-upgrade-drupal-9-js": () => import("./../../../src/pages/blog/upgrade-drupal-9.js" /* webpackChunkName: "component---src-pages-blog-upgrade-drupal-9-js" */),
  "component---src-pages-blog-why-invest-in-nearshore-software-development-js": () => import("./../../../src/pages/blog/why-invest-in-nearshore-software-development.js" /* webpackChunkName: "component---src-pages-blog-why-invest-in-nearshore-software-development-js" */),
  "component---src-pages-blog-zero-party-data-healthcare-industry-js": () => import("./../../../src/pages/blog/zero-party-data-healthcare-industry.js" /* webpackChunkName: "component---src-pages-blog-zero-party-data-healthcare-industry-js" */),
  "component---src-pages-blog-zero-party-data-js": () => import("./../../../src/pages/blog/zero-party-data.js" /* webpackChunkName: "component---src-pages-blog-zero-party-data-js" */),
  "component---src-pages-blog-zielabi-app-development-case-study-js": () => import("./../../../src/pages/blog/zielabi-app-development-case-study.js" /* webpackChunkName: "component---src-pages-blog-zielabi-app-development-case-study-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-why-invest-in-nearshore-software-development-js": () => import("./../../../src/pages/news/why-invest-in-nearshore-software-development.js" /* webpackChunkName: "component---src-pages-news-why-invest-in-nearshore-software-development-js" */),
  "component---src-pages-services-acquia-and-drupal-development-js": () => import("./../../../src/pages/services/acquia-and-drupal-development.js" /* webpackChunkName: "component---src-pages-services-acquia-and-drupal-development-js" */),
  "component---src-pages-services-design-js": () => import("./../../../src/pages/services/design.js" /* webpackChunkName: "component---src-pages-services-design-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-services-magnolia-development-company-js": () => import("./../../../src/pages/services/magnolia-development-company.js" /* webpackChunkName: "component---src-pages-services-magnolia-development-company-js" */),
  "component---src-pages-services-marketing-strategy-js": () => import("./../../../src/pages/services/marketing-strategy.js" /* webpackChunkName: "component---src-pages-services-marketing-strategy-js" */),
  "component---src-pages-services-microsoft-dynamics-365-development-js": () => import("./../../../src/pages/services/microsoft-dynamics-365-development.js" /* webpackChunkName: "component---src-pages-services-microsoft-dynamics-365-development-js" */),
  "component---src-pages-services-mobile-development-js": () => import("./../../../src/pages/services/mobile-development.js" /* webpackChunkName: "component---src-pages-services-mobile-development-js" */),
  "component---src-pages-services-nearshore-software-development-js": () => import("./../../../src/pages/services/nearshore-software-development.js" /* webpackChunkName: "component---src-pages-services-nearshore-software-development-js" */),
  "component---src-pages-services-search-engine-marketing-js": () => import("./../../../src/pages/services/search-engine-marketing.js" /* webpackChunkName: "component---src-pages-services-search-engine-marketing-js" */),
  "component---src-pages-services-seo-js": () => import("./../../../src/pages/services/seo.js" /* webpackChunkName: "component---src-pages-services-seo-js" */),
  "component---src-pages-services-software-development-js": () => import("./../../../src/pages/services/software-development.js" /* webpackChunkName: "component---src-pages-services-software-development-js" */),
  "component---src-pages-services-website-development-js": () => import("./../../../src/pages/services/website-development.js" /* webpackChunkName: "component---src-pages-services-website-development-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-works-actelion-js": () => import("./../../../src/pages/works/actelion.js" /* webpackChunkName: "component---src-pages-works-actelion-js" */),
  "component---src-pages-works-car-4-you-js": () => import("./../../../src/pages/works/car4you.js" /* webpackChunkName: "component---src-pages-works-car-4-you-js" */),
  "component---src-pages-works-ceibs-js": () => import("./../../../src/pages/works/ceibs.js" /* webpackChunkName: "component---src-pages-works-ceibs-js" */),
  "component---src-pages-works-credit-suisse-football-js": () => import("./../../../src/pages/works/credit-suisse-football.js" /* webpackChunkName: "component---src-pages-works-credit-suisse-football-js" */),
  "component---src-pages-works-credit-suisse-js": () => import("./../../../src/pages/works/credit-suisse.js" /* webpackChunkName: "component---src-pages-works-credit-suisse-js" */),
  "component---src-pages-works-crypto-valley-academy-js": () => import("./../../../src/pages/works/crypto-valley-academy.js" /* webpackChunkName: "component---src-pages-works-crypto-valley-academy-js" */),
  "component---src-pages-works-elkuch-js": () => import("./../../../src/pages/works/elkuch.js" /* webpackChunkName: "component---src-pages-works-elkuch-js" */),
  "component---src-pages-works-flavorwiki-js": () => import("./../../../src/pages/works/flavorwiki.js" /* webpackChunkName: "component---src-pages-works-flavorwiki-js" */),
  "component---src-pages-works-frankfurt-school-js": () => import("./../../../src/pages/works/frankfurt-school.js" /* webpackChunkName: "component---src-pages-works-frankfurt-school-js" */),
  "component---src-pages-works-iron-deficiency-day-js": () => import("./../../../src/pages/works/iron-deficiency-day.js" /* webpackChunkName: "component---src-pages-works-iron-deficiency-day-js" */),
  "component---src-pages-works-iron-deficiency-js": () => import("./../../../src/pages/works/iron-deficiency.js" /* webpackChunkName: "component---src-pages-works-iron-deficiency-js" */),
  "component---src-pages-works-js": () => import("./../../../src/pages/works.js" /* webpackChunkName: "component---src-pages-works-js" */),
  "component---src-pages-works-kanebo-sensai-js": () => import("./../../../src/pages/works/kanebo-sensai.js" /* webpackChunkName: "component---src-pages-works-kanebo-sensai-js" */),
  "component---src-pages-works-lutheran-js": () => import("./../../../src/pages/works/lutheran.js" /* webpackChunkName: "component---src-pages-works-lutheran-js" */),
  "component---src-pages-works-nuyu-js": () => import("./../../../src/pages/works/nuyu.js" /* webpackChunkName: "component---src-pages-works-nuyu-js" */),
  "component---src-pages-works-project-firefly-js": () => import("./../../../src/pages/works/project-firefly.js" /* webpackChunkName: "component---src-pages-works-project-firefly-js" */),
  "component---src-pages-works-qiio-js": () => import("./../../../src/pages/works/qiio.js" /* webpackChunkName: "component---src-pages-works-qiio-js" */),
  "component---src-pages-works-residenz-forch-js": () => import("./../../../src/pages/works/residenz-forch.js" /* webpackChunkName: "component---src-pages-works-residenz-forch-js" */),
  "component---src-pages-works-saut-js": () => import("./../../../src/pages/works/saut.js" /* webpackChunkName: "component---src-pages-works-saut-js" */),
  "component---src-pages-works-sigma-aldrich-js": () => import("./../../../src/pages/works/sigma-aldrich.js" /* webpackChunkName: "component---src-pages-works-sigma-aldrich-js" */),
  "component---src-pages-works-simple-risk-js": () => import("./../../../src/pages/works/simple-risk.js" /* webpackChunkName: "component---src-pages-works-simple-risk-js" */),
  "component---src-pages-works-swise-js": () => import("./../../../src/pages/works/swise.js" /* webpackChunkName: "component---src-pages-works-swise-js" */),
  "component---src-pages-works-swiss-finance-institute-js": () => import("./../../../src/pages/works/swiss-finance-institute.js" /* webpackChunkName: "component---src-pages-works-swiss-finance-institute-js" */),
  "component---src-pages-works-university-of-st-gallen-js": () => import("./../../../src/pages/works/university-of-st-gallen.js" /* webpackChunkName: "component---src-pages-works-university-of-st-gallen-js" */),
  "component---src-pages-works-vifor-pharma-js": () => import("./../../../src/pages/works/vifor-pharma.js" /* webpackChunkName: "component---src-pages-works-vifor-pharma-js" */),
  "component---src-pages-works-womens-football-js": () => import("./../../../src/pages/works/womens-football.js" /* webpackChunkName: "component---src-pages-works-womens-football-js" */),
  "component---src-pages-works-zollinger-stiftung-js": () => import("./../../../src/pages/works/zollinger-stiftung.js" /* webpackChunkName: "component---src-pages-works-zollinger-stiftung-js" */)
}

